import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Element from "../library/element";
import Module from "../library/module";

function Wines() {

    const [Data, setData] = useState([])
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242120' AND page_id='_page_unsere_weine'`)
        if (result.data.length > 0) {
            const image = JSON.parse(result.data[0]["content"])
            var l = [];
            for (let i = 0; i < image.length; i++) {
                l[i] = {
                    status: true,
                    data:
                        <div className="loader-container border" style={{ height: "700px", borderRadius: "10px", backgroundColor: "#f0f0f0" }}>
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="loader"></div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="text-center loader-title mt-4">Wird geladen...</div>
                                </div>
                            </div>
                        </div>
                };
            }
            setData(l)
            setTimeout(() => {
                var r = [];
                for (let i = 0; i < image.length; i++) { r[i] = { status: false, data: image[i]["blob"] }; }
                setData(r)
            }, 2000);
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Module
                module="section"
                section={{
                    classname: "container",
                    topclassname: "bg-thawolo lg-pt-c10 lg-pb-c1 md-pt-c1 md-pb-c0",
                    title: <div className="md-pt-c1 lg-pt-c4 lg-pb-c0">Köstliches italienisches Essen, jederzeit!</div>,
                    text: <div className="md-pt-c1 lg-pt-c0 lg-pb-c4">Unser Restaurant ist bekannt für seine köstlichen italienischen Gerichte und Weine.</div>
                }}>
                <div className="row d-flex justify-content-center">
                    {
                        (Data || []).map((d, x) => {
                            return (
                                d["status"] ?
                                    <div className="col-lg-6 mb-4">
                                        {d["data"]}
                                    </div>
                                    :
                                    <div className="col-lg-6 mb-4">
                                        <img src={d["data"]} className="wine-image shadow-sm" />
                                    </div>
                            )
                        })
                    }
                </div>
            </Module>
        </>
    )
}
export default Wines;