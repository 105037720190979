import { useEffect, useState } from "react";
import Module from "../library/module";
import axios from "axios";
import { Helmet } from "react-helmet";
import Element from "../library/element";

function Galery() {
    const [Data, setData] = useState([])
    const [Seo, setSeo] = useState({
        title: "",
        description: "",
        keywords: ""
    })
    const Load = async () => {
        const result = await axios.get("https://ristorante-olivo.ch/api/api.php?params=select&table=kp_page&conditions=company_id='20242120' AND page_id='_page_galerie'")
        const seoresponse = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page_seo&conditions=company_id='20242120' AND page_id='_page_galerie'`)
        if (result.data.length > 0) {
            const image = JSON.parse(result.data[0]["content"])
            var l = [];
            for (let i = 0; i < image.length; i++) {
                l[i] = {
                    status: true,
                    data:
                        <div className="loader-container border" style={{ height: "300px", borderRadius: "10px", backgroundColor: "#f0f0f0" }}>
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="loader"></div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="text-center loader-title mt-4">Wird geladen...</div>
                                </div>
                            </div>
                        </div>
                };
            }
            setData(l)


            setTimeout(() => {
                var r = [];
                for (let i = 0; i < image.length; i++) { r[i] = { status: false, data: image[i]["blob"] }; }
                setData(r)
            }, 2000);
        }
        if (seoresponse.data.length > 0) {
            var r = "";
            if (seoresponse["data"]["keyword"] !== "") {
                var j = JSON.parse(seoresponse["data"][0]["keyword"]);
                for (let i = 0; i < j.length; i++) {
                    r += j[i]["keyword"] + ", ";
                }
            }
            setSeo({
                title: seoresponse["data"][0]["title"],
                description: seoresponse["data"][0]["description"],
                keywords: r
            })
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ristorante Thawolo | {Seo.title}</title>
                <link rel="canonical" href="https://www.thawolo.ch/" />
                <meta name="description" content={Seo.description} />
                <meta name="keywords" content={Seo.keywords} />
                <meta property="og:title" name="description" content={Seo.title} />
                <meta property="og:description" name="description" content={Seo.description} />
            </Helmet>
            <Module module="section" section={{
                topclassname: "bg-thawolo lg-pt-c10 lg-pb-c1 md-pt-c1 md-pb-c0",
                classname: "container",
                title: <div className="md-pb-c2">Galerie</div>,
                text: ""
            }}>
                <div className="row lg-pt-c5 lg-pb-c1">
                    {
                        (Data || []).map((d, x) => {
                            return (
                                d["status"] ?
                                    <div className="col-lg-4 mb-4">
                                        {d["data"]}
                                    </div>
                                    :
                                    <div className="col-lg-4 mb-4">
                                        <img src={d["data"]} className="galery-image shadow-sm" />
                                    </div>
                            )
                        })
                    }
                </div>
            </Module>
        </>
    )
}
export default Galery;