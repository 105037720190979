import { Route, Routes } from "react-router-dom";
import Menu from "./pages/menu";
import Feiern from "./pages/feiern";
import Wines from "./pages/wines";
import Booking from "./pages/booking";
import Contact from "./pages/contact";
import Jobs from "./pages/jobs";
import Galery from "./pages/galery";
import Home from "./pages/home";

import "./assets/css/bootstrap.css"
import "./assets/css/style.css"
import "./assets/css/content.css"
import "./assets/css/fontawsome/css/all.css"
import "./assets/css/fontawsome/css/brands.css"
import "./assets/css/fontawsome/css/fontawesome.css"
import "./assets/css/fontawsome/css/regular.css"
import "./assets/css/fontawsome/css/solid.css"
import "./assets/css/fontawsome/css/svg-with-js.css"
import "./assets/css/fontawsome/css/v4-font-face.css"
import "./assets/css/fontawsome/css/v4-shims.css"
import "./assets/css/fontawsome/css/v5-font-face.css"
import Module from "./library/module";
import { useEffect, useState } from "react";
import Element from "./library/element";
import axios from "axios";

function Layout() {
  const [BuildData, setBuildData] = useState({
    name: "",
    phone: "",
    email: "",
    maps: "",
    city: "",
    country: "",
    district: "",
    address: "",
    logowhite: "",
    logocolor: ""
  })
  const Load = async () => {

    const build = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_build&conditions=company_id='20242120'`)
    const build_address = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_build_address&conditions=company_id='20242120'`)
    const build_communication = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_build_communication&conditions=company_id='20242120'`)
    const build_social = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_build_social&conditions=company_id='20242120'`)
    if (build_social.data.length > 0) {
      setBuildData({
        name: build.data[0]["name"],
        phone: build_communication.data[0]["phone"],
        email: build_communication.data[0]["email"],
        maps: build_social.data[0]["maps"],
        city: build_address.data[0]["city"],
        country: build_address.data[0]["country"],
        district: build_address.data[0]["district"],
        address: build_address.data[0]["address"],
        socail: JSON.parse(build_social.data[0]["link"]),
        logowhite: build.data[0]["logo_white"] !== "" ? JSON.parse(build.data[0]["logo_white"])["blob"] : "",
        logocolor: build.data[0]["logo_color"] !== "" ? JSON.parse(build.data[0]["logo_color"])["blob"] : "",
      })
    }
  }
  useEffect(() => {
    Load()
  }, [])


  return (
    <>
      <Module module="header" header={BuildData} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu/:name" element={<Menu />} />
        <Route path="/galerie" element={<Galery />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/kontakt" element={<Contact data={BuildData} />} />
        <Route path="/tisch-reservation" element={<Booking />} />
        <Route path="/unsere-weine" element={<Wines />} />
        <Route path="/feiern" element={<Feiern />} />
      </Routes>
      <Module module="footer" footer={BuildData} />
      <Element element="top" />
    </>
  );
}

export default Layout;
