import { useEffect, useState } from "react";
import Element from "../library/element";
import Module from "../library/module";
import axios from "axios";

function Jobs() {

    const [Indicator, setIndicator] = useState(false)
    const [Gender, setGender] = useState(0)
    const [Name, setName] = useState("")
    const [Surname, setSurname] = useState("")
    const [Phone, setPhone] = useState("")
    const [Email, setEmail] = useState("")
    const [Position, setPosition] = useState("")
    const [Dates, setDates] = useState("")
    const [Commnet, setCommnet] = useState("")
    const [File, setFile] = useState("")
    const [Error, setError] = useState({ status: true, target: 0, type: "", text: "" })
    const [Data, setData] = useState({ image: "", slogan: "", title: "", text: "" })

    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242120' AND page_id='_page_jobs'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);
            setData({
                image: r["image"]["blob"],
                slogan: r["slogan"],
                title: r["title"],
                text: r["text"]
            })
        }
    }
    const Control = () => {
        if (Gender === 0) { setError({ status: false, target: 1, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Name === "") { setError({ status: false, target: 2, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Surname === "") { setError({ status: false, target: 3, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Phone === "") { setError({ status: false, target: 4, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Email === "") { setError({ status: false, target: 5, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Position === "") { setError({ status: false, target: 6, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Dates === "") { setError({ status: false, target: 7, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Commnet === "") { setError({ status: false, target: 8, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (File === "") { setError({ status: false, target: 9, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        return true;
    }
    const Send = async () => {
        setIndicator(true)
        if (Control()) {
            const root = Element({ element: "root", root: "default" });
            const data = new FormData();
            data.append('file', File);
            const axdata = await axios.post(Element({ element: "root", root: "upload" }), data);
            //FIRMA
            const companyBody = `
                Sie haben eine neue Bewerbung erhalten. Die erforderlichen Informationen finden Sie unten.
                <br/><br/>
                Anrede: ${Gender}
                <br/>
                Name und Vorname: ${Name} ${Surname}
                <br/>
                Gewünschte eintritt: ${Dates}
                <br/>
                E-Mail: ${Email}
                <br/>
                Telefon: ${Phone}
                <br/>
                Gewünschte Stelle: ${Position}
                <br/>
                Bemerkungen: ${Commnet}
                <br/>
                CV Herunterladen: <a href="https://www.thawolo.ch/api/upload/${axdata.data.data}">CV</a>
                <br/>
            `
            const companyData = {
                id: "202421120",
                email: ["info@thawolo.ch", "n.n.akcakaya@gmail.com"],
                title: "Sie haben eine neue Bewerbung erhalten",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })


            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${Name} ${Surname}` : `Sehr geehrter ${Gender} ${Name} ${Surname}`}
                <br/><br/>
                Ihre Bewerbung ist bei uns eingegangen. Vielen Dank für Ihr Interesse. Wir werden Ihre Bewerbung prüfen und uns schnellstmöglich bei Ihnen melden.
                <br/><br/>
                Details Ihrer Reservierung
                <br/>
                Freundlichen Grüsse
                <br/><br/>
                Thawolo Team
                <br/><br/>
                Ristorante Thawolo da Chrottebach
                <br/>
                Gotthardstrasse 36 
                <br/>
                8800 Thalwil
                <br/><br/>
                info@thawolo.ch
                <br/>
                044 710 91 91
                <br/>
            `;
            const customerData = {
                id: "202421120",
                email: ["" + Email + ""],
                title: "Ihre Bewerbung ist bei uns eingegangen.",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Ihre Bewerbung wurde korrekt erfasst. Wir werden uns bald mit Ihnen in Verbindung setzen." });
            setGender("")
            setName("")
            setSurname("")
            setPhone("")
            setEmail("")
            setPosition("")
            setDates("")
            setCommnet("")
            setFile("")
            setIndicator(false)
        }
    }
    useEffect(() => {
        Load()
    }, [])
    return (
        <>
            <Module module="section" section={{ topclassname: "bg-thawolo", classname: "p-0" }}>
                <div className="row m-0">
                    <div className="col-lg-6 p-0">
                        <div style={{ backgroundImage: `url(${Data["image"]})` }} className="jobs-image" ></div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row d-flex justify-content-center lg-pt-c10 lg-pb-c10 md-pt-c2 md-pb-c2">
                            <div className="col-lg-8">
                                <div className="jobs-title lg-pb-5 md-pb-10">
                                    {Data["title"]}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="jobs-text lg-pb-c2 md-pb-c2" dangerouslySetInnerHTML={{ __html: Data["text"] }}></div>
                            </div>
                            <div className="col-lg-12">
                                <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                                    {Error.text}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Andrede *</label>
                                                    <select className={`form-control h-50px shadow-sm cursor-pointer ${Error.target === 1 ? "border-red" : ""}`} onChange={(e) => { setGender(e.target.value); setError({ status: true, type: "", text: "" }) }}>
                                                        <option value={0} >Andrede</option>
                                                        <option value={"Frau"}>Frau</option>
                                                        <option value={"Herr"}>Herr</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2"></div>
                                            <div className="col-lg-6 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Nachname *</label>
                                                    <input className={`form-control h-50px shadow-sm ${Error.target === 2 ? "border-red" : ""}`} placeholder="Nachname *" value={Name} onChange={(e) => { setName(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Vorname *</label>
                                                    <input className={`form-control h-50px shadow-sm ${Error.target === 3 ? "border-red" : ""}`} placeholder="Vorname *" value={Surname} onChange={(e) => { setSurname(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Telefon *</label>
                                                    <input className={`form-control h-50px shadow-sm ${Error.target === 4 ? "border-red" : ""}`} placeholder="Telefon *" value={Phone} onChange={(e) => { setPhone(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">E-Mail *</label>
                                                    <input className={`form-control h-50px shadow-sm ${Error.target === 5 ? "border-red" : ""}`} placeholder="E-Mail *" value={Email} onChange={(e) => { setEmail(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Gewünschte eintritt  *</label>
                                                    <input className={`form-control h-50px shadow-sm ${Error.target === 6 ? "border-red" : ""}`} placeholder="Gewünschte eintritt  *" value={Position} onChange={(e) => { setPosition(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Datum auswählen  *</label>
                                                    <Module module="calendar" calendar={{ response: (e) => { setDates(e); setError({ status: true, type: "", text: "" }) }, classname: Error.target === 7 ? "border-red" : "" }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Bemerkungen  *</label>
                                                    <textarea className={`form-control shadow-sm ${Error.target === 8 ? "border-red" : ""}`} placeholder="Bemerkungen *" rows={5} value={Commnet} onChange={(e) => { setCommnet(e.target.value); setError({ status: true, type: "", text: "" }) }}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <label className="mb-1 label-text">Bewerbungsdossier hinzufügen *</label>
                                                    <Module module="upload" upload={{ response: (e) => { setFile(e); setError({ status: true, type: "", text: "" }) }, classname: Error.target === 9 ? "border-red" : "" }} />
                                                    <label className="mb-1 label-text">Maximal 5 Dateien möglich. 10 MB Limit. Erlaubte Dateitypen: pdf.</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-2 md-p-0">
                                                <div className="mb-2">
                                                    <button className="btn btn-thawolo w-100 d-flex justify-content-center align-items-center" onClick={() => { Send() }}>
                                                        {Indicator ? <div className="indicator me-1"></div> : <></>}
                                                        {Indicator ? "Senden..." : "Absenden"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Jobs;