import Module from "../library/module";
import WeaterBg from "../assets/img/waeter-bg.png"
import { useEffect, useState } from "react";
import axios from "axios";
import Element from "../library/element";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Home() {

    const navigate = useNavigate()
    const [Content, setContent] = useState({
        banner: {
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" }
        },
        aboutus: {
            section_title: "",
            section_text: "",
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" },
            bottom_title: "",
        },
        menu: {
            section_title: "",
            section_text: "",
            blob: [
                {
                    id: 1,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 2,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 3,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 4,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
            ],
        },
        feiern: {
            section_title: "",
            section_text: "",
            blob: [
                {
                    id: 1,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 2,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 3,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
                {
                    id: 4,
                    blob: { id: 0, blob: "", file: "" },
                    title: "",
                    link: ""
                },
            ],
        },
        tage: {
            section_title: "",
            section_text: "",
            title: "",
            text: "",
            blob: { id: 0, blob: "", file: "" },
            bottom_title: "",
        },

    })
    const [Seo, setSeo] = useState({
        title: "",
        description: "",
        keywords: ""
    })
    const Load = async () => {
        const pageresponse = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242120' AND page_id='_page_home'`)
        const seoresponse = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page_seo&conditions=company_id='20242120' AND page_id='_page_home'`)
        if (pageresponse.data.length > 0) {
            setContent(JSON.parse(pageresponse.data[0]["content"]),)
        }
        if (seoresponse.data.length > 0) {
            var r = "";
            if (seoresponse["data"]["keyword"] !== "") {
                var j = JSON.parse(seoresponse["data"][0]["keyword"]);
                for (let i = 0; i < j.length; i++) {
                    r += j[i]["keyword"] + ", ";
                }
            }
            setSeo({
                title: seoresponse["data"][0]["title"],
                description: seoresponse["data"][0]["description"],
                keywords: r
            })
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pizzeria Capri | {Seo.title}</title>
                <link rel="canonical" href="https://www.thawolo.ch/" />
                <meta name="description" content={Seo.description} />
                <meta name="keywords" content={Seo.keywords} />
                <meta property="og:title" name="description" content={Seo.title} />
                <meta property="og:description" name="description" content={Seo.description} />
            </Helmet>
            <Module module="banner" banner={{ classname: "container", url: Content["banner"]["blob"]["blob"] }}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="banner-info">
                            <div className="banner-title">{Content["banner"]["title"]}</div>
                            <div className="banner-text">{Content["banner"]["text"]}</div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" section={{
                classname: "container",
                topclassname: "lg-pt-c3 lg-pb-c3 md-pt-c2 md-pb-c2",
                title: <div className="md-pt-c0 md-pb-c1">{Content["aboutus"]["section_title"]}</div>,
                text: ""
            }}>
                <div className="row lg-pt-c4 lg-pb-c5 md-pt-c2 md-pb-c0">
                    <div className="col-lg-6">
                        <div className="section-inner">
                            <div>
                                <img src={Content["aboutus"]["blob"]["blob"]} className="image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-inner">
                            <div>
                                <div className="title lg-pt-c1 lg-pb-c1 md-pt-c4 md-pb-c2">
                                    {Content["aboutus"]["title"]}
                                </div>
                                <div className="text lg-pt-10" dangerouslySetInnerHTML={{ __html: Content["aboutus"]["text"] }}></div>
                                <div className="signatur lg-pt-10 md-pt-20">
                                    {Content["aboutus"]["bottom_title"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
            <Module module="section" section={{
                url: WeaterBg,
                classname: "container",
                topclassname: "bg-thawolo lg-pt-c4 lg-pb-c0 md-pt-c1 md-pb-c0",
                title: <div className="md-pt-c1">{Content["menu"]["section_title"]}</div>,
                text: <div className="lg-pt-15 md-pb-c0">{Content["menu"]["section_text"]}</div>
            }}>
                <div className="row lg-pt-c5 lg-pb-c2 md-pt-c3 md-pb-c0">
                    {
                        (Content["menu"]["blob"] || []).map((d, x) => {
                            return (
                                <div key={`${d["id"]}-list-${x}`} className="col-lg-3 col-6 lg-pb-5 md-pb-20" onClick={() => { navigate(d["link"]) }}>
                                    <div className="section-inner cursor-pointer">
                                        <div className="menu-image" style={{ backgroundImage: `url(${d["blob"]["blob"]})` }}></div>
                                        <div className="button-thawolo">{d["title"]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Module>
            <Module module="section" section={{
                url: WeaterBg,
                topclassname: "bg-thawolo lg-pb-c4",
                classname: "container",
                title: <div className="md-pt-c1">{Content["feiern"]["section_title"]}</div>,
                text: <div className="lg-pt-15 md-pb-c0">{Content["feiern"]["section_text"]}</div>
            }}>
                <div className="row lg-pt-c5 lg-pb-c2 md-pt-c3 md-pb-c0">
                    {
                        (Content["feiern"]["blob"] || []).map((d, x) => {
                            return (
                                <div key={`${d["id"]}-list-${x}`} className="col-lg-3 col-6 lg-pb-5 md-pb-20" onClick={() => { d["title"] === "HAUSLIEFERUNG" ? window.open(d["link"]) : navigate(d["link"]) }}>
                                    <div className="section-inner cursor-pointer">
                                        <div className="menu-image" style={{ backgroundImage: `url(${d["blob"]["blob"]})` }}></div>
                                        <div className="button-thawolo">{d["title"]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Module>
            <Module module="section" section={{
                topclassname: "lg-pt-c5 lg-pb-c5",
                classname: "container",
                title: <div  className="md-pt-c1">{Content["tage"]["section_title"]}</div>,
                text: <div className="lg-pt-15 md-pb-c0">{Content["tage"]["section_text"]}</div>
            }}>
                <div className="row lg-pt-c5 lg-pb-c2 md-pt-c3 md-pb-c0">
                    <div className="col-lg-6">
                        <div className="section-inner">
                            <div>
                                <img src={Content["tage"]["blob"]["blob"]} className="image" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-inner">
                            <div>
                                <div className="title md-pt-20">{Content["tage"]["title"]}</div>
                                <div className="text lg-pt-10 md-pt-20" dangerouslySetInnerHTML={{ __html: Content["tage"]["text"] }}></div>
                                <div className="signatur lg-pt-10 md-pt-15">
                                    {Content["tage"]["bottom_title"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Home;