import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import FooterBachround from "../assets/img/footer-bg.webp"

import CapriBanner from "../assets/img/capri-banner.webp"
import OlivioBanner from "../assets/img/olivio-banner.webp"
import MenuBackground from "../assets/img/header-menu-bg.webp"

import Tagesmenu from "../assets/img/tagesmenu-menu-image.webp"
import Speisekarte from "../assets/img/speisekarte-menu-image.webp"
import Desserts from "../assets/img/desserts-menu-image.webp"
import Aktuel from "../assets/img/aktuel-menu-image.webp"
import Bankett from "../assets/img/bankett-menu-image.webp"

const Header = ({ header = {
    name: "",
    phone: "",
    email: "",
    maps: "",
    city: "",
    country: "",
    district: "",
    address: "",
    social: "",
    logowhite: "",
    logocolor: ""
} }) => {
    const navigate = useNavigate()
    const [Sticky, setSticky] = useState(0)
    const [Active, setActive] = useState(false)
    const [Toggle, setToggle] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 80) {
                setSticky(1)
            } else {
                setSticky(0)
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.scroll({
                top: 0,
                left: 0,
            });
        };
    }, []);
    const Scroll = () => {
        useEffect(() => {
            function handleScroll() {
                var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
                var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
                var scrolled = (winScroll / height) * 100;

                document.getElementById("progressBar").style.width = scrolled + "%";
            }
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

        return (
            <div className="progress-bar" id="progressBar" style={{ background: '#c19977', height: '5px', width: '0%', position: 'fixed', zIndex: 1040 }}>
            </div>
        );
    }
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, [window.location.pathname])
    return (
        <>
            <div className={`top md-d-none ${Sticky === 1 ? "sticky" : ""}`}>
                <div className="container">
                    <div className="d-flex">
                        <div className="w-100 d-flex justify-content-start">
                            <div className="top-social"><i className="fa-brands fa-facebook fs-2 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/CapriAdliswil`) }}></i></div>
                            <div className="top-social"><i className="fa-brands fa-square-instagram fs-2 cursor-pointer" onClick={() => { window.open(`https://www.instagram.com/capri.pizzeria/`) }}></i></div>
                        </div>
                        <div className="w-100 d-flex justify-content-end">
                            <div className="top-link" onClick={() => { navigate(`/unsere-weine`) }}>Unsere Weine</div>
                            <div className="top-link" onClick={() => { window.open("https://www.pizzapp.ch/pizza/") }}>Hauslieferung</div>
                            <div className="top-link" onClick={() => { navigate(`/feiern`) }}>Feiern</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`header ${Sticky === 1 ? "sticky" : ""}`}>
                <div className="container position-relative">
                    <div className="lg-d-flex">
                        <div className="w-100px">
                            <div className="header-logo cursor-pointer" onClick={() => { navigate(`/`) }}>
                                <img src={`https://ristorante-olivo.ch/api/upload/${header.logowhite}`} />
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <div className="header-item" onClick={() => { navigate(`/`) }}>HOME</div>
                            <div className="header-item">
                                MENÜ
                                <i className="fa-solid fa-chevron-down ms-1"></i>
                                <div className="header-item-modal">
                                    <div className="w-100 modal-item" onClick={() => { navigate(`/menu/tagesmenu`) }}>
                                        <div className="modal-image" style={{ backgroundImage: `url(${Tagesmenu})` }}></div>
                                        <div className="modal-title">TAGESMENÜ</div>
                                    </div>
                                    <div className="w-100 modal-item" onClick={() => { navigate(`/menu/speisekarte`) }}>
                                        <div className="modal-image" style={{ backgroundImage: `url(${Speisekarte})` }}></div>
                                        <div className="modal-title">SPEISEKARTE</div>
                                    </div>
                                    <div className="w-100 modal-item" onClick={() => { navigate(`/menu/desserts`) }}>
                                        <div className="modal-image" style={{ backgroundImage: `url(${Desserts})` }}></div>
                                        <div className="modal-title">DESSERTS</div>
                                    </div>
                                    <div className="w-100 modal-item" onClick={() => { navigate(`/menu/aktuell`) }}>
                                        <div className="modal-image" style={{ backgroundImage: `url(${Aktuel})` }}></div>
                                        <div className="modal-title">AKTUELL</div>
                                    </div>
                                    <div className="w-100 modal-item" onClick={() => { navigate(`/menu/bankett`) }}>
                                        <div className="modal-image" style={{ backgroundImage: `url(${Bankett})` }}></div>
                                        <div className="modal-title">BANKETT</div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-item" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`) }}>HAUSLIEFERUNG</div>
                            <div className="header-item" onClick={() => { navigate(`/galerie`) }}>GALERIE</div>
                            <div className="header-item" onClick={() => { navigate(`/jobs`) }}>JOBS</div>
                            <div className="header-item" onClick={() => { navigate(`/kontakt`) }}>KONTAKT</div>
                        </div>
                        <div className="w-100px d-flex justify-content-end">
                            <div className="header-button">
                                <button className="btn btn-thawolo" onClick={() => { navigate(`/tisch-reservation`) }}>TISCH RESERVATION</button>
                            </div>
                        </div>
                    </div>
                    <div className="md-d-flex">
                        <div className="w-100px">
                            <div className="header-logo cursor-pointer" onClick={() => { navigate(`/`) }}>
                                <img src={`https://ristorante-olivo.ch/api/upload/${header.logowhite}`} />
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-end">
                            <div className="hamburger-menu" onClick={() => { setActive(true) }}>
                                <i className="fa-solid fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <Scroll />
            </div>
            <div className={`drawer ${Active ? "active" : ""} shadow-sm`} style={{ backgroundImage: `url(${MenuBackground})` }}>
                <div className="drawer-header">
                    <div className="w-100 p-2" onClick={() => { navigate(`/`) }}>
                        <img src={`https://ristorante-olivo.ch/api/upload/${header.logowhite}`} className="logo-image" />
                    </div>
                    <div className="w-100 p-2 d-flex justify-content-end">
                        <i className="fa-solid fa-circle-xmark text-white fs-2hx mr-20px" onClick={() => { setActive(false) }}></i>
                    </div>
                </div>
                <div className="drawer-body">
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/" }}>HOME</div>
                    <div className="drawer-body-item" onClick={() => { Toggle ? setToggle(false) : setToggle(true) }}>
                        <div className="w-100">MENÜ</div>
                        <div className="w-100 d-flex justify-content-end me-2">
                            <i className="fa-solid fa-chevron-down fs-1"></i>
                        </div>
                    </div>
                    <div className={`drawer-body-item-toggle ${Toggle ? "active" : ""}`}>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/tagesmenu" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            TAGESMENÜ
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/speisekarte" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            SPEISEKARTE
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/desserts" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            DESSERTS
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/aktuell" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            AKTUELL
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/bankett" }}>
                            <i className="fa-solid fa-circle-dot me-1 fs-0hx"></i>
                            BANKETT
                        </div>
                    </div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/unsere-weine" }}>UNSERE WEINE</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/tisch-reservation" }}>TISCH RESERVATION</div>
                    <div className="drawer-body-item" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`, "blank") }}>HAUSLIEFERUNG</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/feiern" }}>FEIERN</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/galerie" }}>GALERIE</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/kontakt" }}>KONTAKT</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/jobs" }}>JOBS</div>
                </div>
                <div className="drawer-footer">
                    <div className="drawer-footer-item" onClick={() => { window.open(`mailto:${header.email}`) }}>{header.email}</div>
                    <div className="drawer-footer-item" onClick={() => { window.open(`tel:${header.phone}`) }}>{header.phone}</div>
                    <div className="drawer-footer-social">
                        <i className="fa-brands fa-facebook fs-1hx text-white me-2 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/profile.php?id=100064681705058`, "blank") }}></i>
                        <i className="fa-brands fa-square-instagram fs-1hx text-white cursor-pointer" onClick={() => { window.open(`https://www.instagram.com/ristorante.olivo/`, "blank") }}></i>
                    </div>
                </div>
            </div>
            <div className={`drawer-overlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }}></div>

            <div className="md-height-80px"></div>
        </>
    )
}
const Footer = ({ footer = {
    name: "",
    phone: "",
    email: "",
    maps: "",
    city: "",
    country: "",
    district: "",
    address: "",
    social: ""
} }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="footer" style={{ backgroundImage: `url(${FooterBachround})` }}>
                <div className="container lg-pt-c5 lg-pb-c5 md-pt-c5 md-pb-c5">
                    <div className="footer-inner">
                        <div className="row">
                            <div className="col-lg-4 col-12 md-pb-c3">
                                <div className="footer-title">KONTAKT</div>
                                <div className="footer-text lg-pt-c1 md-pt-c1 d-flex">
                                    <div className="w-50px me-1"><i className="fa-solid fa-location-dot"></i></div>
                                    <div className="w-100">{footer["name"]} da {footer["district"]} {footer["address"]}</div>
                                </div>
                                <div className="footer-text lg-pt-c1 md-pt-c1 d-flex cursor-pointer" onClick={() => { window.open(`mailto:${footer["email"]}`) }}>
                                    <div className="w-50px me-1"><i className="fa-solid fa-envelope"></i></div>
                                    <div className="w-100">{footer["email"]}</div>
                                </div>
                                <div className="footer-text lg-pt-c1 md-pt-c1 d-flex cursor-pointer" onClick={() => { window.open(`tel:${footer["phone"]}`) }}>
                                    <div className="w-50px me-1"><i className="fa-solid fa-square-phone-flip"></i></div>
                                    <div className="w-100">{footer["phone"]}</div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 md-pb-c3">
                                <div className="footer-title">ÖFFNUNGSZEITEN</div>
                                <div className="footer-text d-flex lg-pt-c1 md-pt-c1">
                                    <div className="w-40px"><i class="fa-solid fa-clock me-1"></i></div>
                                    <div className="w-100">Mo-Sa:</div>
                                    <div className="w-100"><div className="ms-1">07:00-22:30 Uhr</div></div>
                                </div>
                                <div className="footer-text d-flex lg-pt-c1 md-pt-c1">
                                    <div className="w-40px"><i class="fa-solid fa-clock me-1"></i></div>
                                    <div className="w-100">Sonntag & Feiertage:</div>
                                    <div className="w-100"><div className="ms-1">11:00-20:00 Uhr</div></div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-12 md-pb-c3">
                                <div className="footer-title">FILIALEN</div>
                                <img src={CapriBanner} className="footer-banner mt-1 mb-1" onClick={() => { window.open(`https://www.capri-adliswil.ch/`) }} />
                                <img src={OlivioBanner} className="footer-banner mt-1 mb-1" onClick={() => { window.open(`https://ristorante-olivo.ch/`) }} />
                            </div>
                            <div className="col-lg-12 lg-pt-c3 md-pt-3 md-pb-c1" style={{ textAlign: "start" }}>
                                <div className="text-white">© Copyright Designed <a href="https://kompassgroup.ch/">Kompass Group GmbH</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
const Banner = ({ banner, children }) => {
    return (
        <>
            <div className={`banner`} style={{ backgroundImage: `url(${banner.url})` }}>
                <div className={`${banner.classname}`}>
                    {children}
                </div>
            </div>
        </>
    )
}
const Section = ({ section, children }) => {
    return (
        <>
            <div className={section.topclassname} style={{ backgroundImage: `url(${section.url})` }}>
                <div className={`section ${section.classname}`}>
                    <div className="section-title" hidden={section.title ? false : true}>
                        {section.title}
                    </div>
                    <div className="section-text" hidden={section.text ? false : true}>
                        {section.text}
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}
const Highlight = ({ highlight, children }) => {

    return (
        <>

        </>
    )
}
const Upload = ({ upload }) => {
    const clickRef = useRef()
    const [Data, setData] = useState("")
    const Select = (e) => {
        e.preventDefault();
        const [file] = e.target.files;
        setData(file.name)
        upload.response(file)
        if (clickRef.current) {
            clickRef.current.value = "";
        }
    }

    return (
        <>

            <div className={`upload-container shadow-sm ${upload.classname}`} onClick={() => { clickRef.current.click() }}>
                {
                    Data === "" ?
                        <div>
                            <div className="text-center"><i className="fa-solid fa-cloud-arrow-up"></i></div>
                            <div className="text-center">Datei auswählen...</div>
                        </div>
                        :
                        <div>
                            {Data}
                        </div>
                }

            </div>
            <input type="file" ref={clickRef} onChange={Select} hidden />

        </>
    )
}
const Dater = ({ calendar }) => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    const mounths = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    const handleTodayClick = () => {
        const today = new Date();
        setCurrentMonth(today.getMonth());
        setCurrentYear(today.getFullYear());
    };
    const next = () => {
        setCurrentYear(currentMonth === 11 ? currentYear + 1 : currentYear);
        setCurrentMonth((currentMonth + 1) % 12);
    };
    const previous = () => {
        setCurrentYear(currentMonth === 0 ? currentYear - 1 : currentYear);
        setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1);
    };
    const daysInMonth = (iMonth, iYear) => {
        return 32 - new Date(iYear, iMonth, 32).getDate();
    };
    const getDaysOfWeek = () => {
        const startDayIndex = 0; // Pazartesi
        return days.slice(startDayIndex).concat(days.slice(0, startDayIndex));
    };
    const showCalendar = (month, year) => {
        const firstDay = new Date(year, month, 1).getDay();
        const daysOfWeek = getDaysOfWeek();
        const daysInCurrentMonth = daysInMonth(month, year);

        const NumberOfControl = (e) => {
            if (e < 10) {
                return "0" + e;
            } else {
                return e;
            }
        };

        const control = (d, m, y) => {

            if (y === today.getFullYear()) {
                if (m < (today.getMonth() + 1)) {
                    return "disable";
                }
                else if (m === (today.getMonth() + 1)) {
                    if (d < (today.getDate())) {
                        return "disable";
                    }
                    else {
                        return ""
                    }
                } else {
                    return ""
                }
            }
        }

        const SelectControl = (date) => {
            if (currentYear >= today.getFullYear()) {
                if ((currentMonth + 1) === (today.getMonth() + 1)) {
                    if (date >= today.getDate()) {
                        return true
                    }
                } else if ((currentMonth + 1) > (today.getMonth() + 1)) {
                    return true
                }
                else { return false }
            }
            else { return false }
        }

        const calendarBody = Array.from({ length: 6 }, (_, rowIndex) => {
            const row = Array.from({ length: 7 }, (_, columnIndex) => {
                const dayIndex = rowIndex * 7 + columnIndex;
                const date = dayIndex - firstDay + 1;

                if (rowIndex === 0 && columnIndex < firstDay) {
                    const prevMonthDate = daysInMonth(month === 0 ? 11 : month - 1, month === 0 ? year - 1 : year) - firstDay + columnIndex + 1;
                    return (
                        <td key={`empty-${columnIndex}`} className={SelectControl(date) ? "" : "old-date"}>
                            <div className="d-flex align-items-center justify-content-center shadow-sm fs-14 cursor-pointer" style={{ color: "#6c5037", height: "40px" }}>
                                {prevMonthDate}
                            </div>
                        </td>
                    );
                } else if (date > daysInCurrentMonth) {
                    const nextMonthDate = date - daysInCurrentMonth;
                    return (
                        <td key={`empty-${columnIndex}`} className={SelectControl(date) ? "next-date" : "old-date"}>
                            <div
                                onClick={() => { SelectControl(date) && calendar.response({ year: year, month: NumberOfControl(currentMonth + 2), day: NumberOfControl(nextMonthDate) }) }}
                                className="d-flex align-items-center justify-content-center shadow-sm fs-14 cursor-pointer" style={{ color: "#6c5037", height: "40px" }}>
                                {nextMonthDate}
                            </div>
                        </td>
                    );
                } else {
                    return (
                        <td key={date} className={SelectControl(date) ? "current-date" : "old-date"}>
                            <div
                                onClick={() => { SelectControl(date) && calendar.response({ year: year, month: NumberOfControl(month + 1), day: NumberOfControl(date) }) }}
                                className="d-flex align-items-center justify-content-center shadow-sm fs-14 cursor-pointer"
                                style={{
                                    height: "40px",
                                    padding: "2px",
                                    backgroundColor: `${today.getDate() === date && year === today.getFullYear() && month === today.getMonth() ? "#6c5037" : "#fff"}`,
                                    color: `${today.getDate() === date && year === today.getFullYear() && month === today.getMonth() ? "#fff" : "#555"}`,
                                    cursor: "pointer"
                                }}>
                                {control(date, NumberOfControl(month + 1), year) === "disable" ?
                                    <div style={{ opacity: 0.9, pointerEvents: "none" }}>{date}</div>
                                    :
                                    <div className={`${(month) >= today.getMonth() ? "" : "disable"}`
                                    }>{date}</div>}
                            </div>
                        </td>
                    );
                }
            });
            return <tr key={rowIndex}>{row}</tr>;
        });

        return calendarBody;
    };
    return (
        <>
            <div className="row">
                <div className="col-lg-12 p-0">
                    <div className="d-flex">
                        <div className="w-50px">
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "30px", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={previous}>
                                <i class="fa-solid fa-chevron-left text-white"></i>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{ borderRight: "solid 1px #fff", borderLeft: "solid 1px #fff" }}>
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "100%", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={handleTodayClick}>
                                <div className="text-white">{currentYear}</div>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{ borderRight: "solid 1px #fff" }}>
                            <div className=" btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "100%", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={handleTodayClick}>
                                <div className="text-white">Heute</div>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{ borderRight: "solid 1px #fff" }}>
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "100%", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={handleTodayClick}>
                                <div className="text-white">{mounths[currentMonth]}</div>
                            </div>
                        </div>
                        <div className="w-50px d-flex justify-content-end">
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "30px", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={next}>
                                <i class="fa-solid fa-chevron-right text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 p-0">
                    <table className="shadow" style={{ width: "100%", backgroundColor: "#fff" }}>
                        <thead>
                            <tr>
                                {days.map((day, index) => (<th key={index} className="test2">{day}</th>))}
                            </tr>
                        </thead>
                        <tbody id="calendar-body">
                            {showCalendar(currentMonth, currentYear)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
const Timer = ({ timer }) => {
    const [Active, setActive] = useState(false)
    const [Value, setValue] = useState("")
    const hour = [
        { id: 1100, time: "11:00-11:30" },
        { id: 1130, time: "11:30-12:00" },
        { id: 1330, time: "12:00-12:30" },
        { id: 1230, time: "12:30-13:00" },
        { id: 1300, time: "13:00-13:30" },
        { id: 1330, time: "13:30-14:00" },


        { id: 1700, time: "17:00-17:30" },
        { id: 1730, time: "17:30-18:00" },
        { id: 1800, time: "18:00-18:30" },
        { id: 1830, time: "18:30-19:00" },
        { id: 1900, time: "19:00-19:30" },
        { id: 1930, time: "19:30-20:00" },
        { id: 2000, time: "20:00-20:30" },
        { id: 2030, time: "20:30-21:00" },
        { id: 2100, time: "21:00-21:30" },
        { id: 2130, time: "21:30-22:00" },
    ]
    const control = () => {
        const data = new Date();
        const h = data.getHours();
        const m = data.getMinutes();
        return parseInt(h + "" + m);
    }
    const datecontrol = (time) => {
        const today = new Date();
        if (parseInt(timer.selected.split(".")[0]) === parseInt(today.getDate())) {
            if (parseInt(time.split("-")[0].replace(":", "")) >= parseInt(control())) {
                return true
            }
            else {
                return false;
            }

        }
        else {
            return true
        }
    }

    return (
        <>
            <input className={`form-control h-50px shadow-sm cursor-pointer ${timer.classname}`} placeholder="Zeit auswählen *" value={Value} onClick={() => { setActive(true) }} />
            <div className="timer-container">
                <div className={`timer-modal ${Active ? "active" : ""} shadow-sm`}>
                    {
                        timer.selected &&
                        (hour || []).map((d, x) => {
                            return (
                                <div
                                    className={`timer-modal-item ${Value === d ? "active" : ""}  ${datecontrol(d.time) ? "" : "disable"}`}
                                    onClick={() => {
                                        timer.response(d.time);
                                        setValue(d.time);
                                        setActive(false);
                                    }}>
                                    {d.time}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={`timer-overlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }} ></div>
        </>
    )

}
const Calendar = ({ calendar }) => {
    const [Active, setActive] = useState(false)
    const [Value, setValue] = useState("")
    return (
        <>
            <input className={`form-control h-50px shadow-sm cursor-pointer ${calendar.classname}`} placeholder="Datum auswählen *" value={Value} onClick={() => { setActive(true) }} />
            <div className="calendar-modal-container">
                <div className={`calendar-modal shadow-sm ${Active ? "active" : ""}`}>
                    <Dater calendar={{
                        response: (e) => {
                            setValue(`${e["day"]}.${e["month"]}.${e["year"]}`);
                            calendar.response(`${e["day"]}.${e["month"]}.${e["year"]}`);
                            setActive(false)
                        }
                    }} />
                </div>
            </div>
            <div className={`calendar-modal-ovarlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }}></div>
        </>
    )
}


export default function Module({ module, header, footer, children, upload, banner, calendar, timer, section, formcontrol, highlight }) {
    switch (module) {
        case "header": return <Header header={header} />
        case "footer": return <Footer footer={footer} />
        case "upload": return <Upload upload={upload} />
        case "calendar": return <Calendar calendar={calendar} />
        case "timer": return <Timer timer={timer} />
        case "banner": return <Banner banner={banner} children={children} />
        case "section": return <Section section={section} children={children} />
        case "highlight": return <Highlight highlight={highlight} children={children} />



        case "": return <></>
    }
}