import { useEffect, useState } from "react"


const Root = ({ root }) => {
    switch (root) {
        case "default": return "https://ristorante-olivo.ch/api/api.php"
        case "upload": return "https://ristorante-olivo.ch/api/class.upload.php"
        case "file": return "https://ristorante-olivo.ch/api/upload"
    }
}
const Seo = ({ seo }) => {
    return seo.url.toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
        .replace(/&/g, '-and-')
        .replace(/[^a-z0-9\-]/g, '')
        .replace(/-+/g, '-')
        .replace(/^-*/, '')
        .replace(/-*$/, '');
}
const Top = () => {
    const [Sticky, setSticky] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 300) {
                setSticky(1)
            } else {
                setSticky(0)
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    return (
        <div className={`scroll-top cursor-pointer ${Sticky === 1 ? "active" : ""}`} onClick={() => { window.scroll({ top: 0, left: 0, behavior: "smooth" })}}>
            <i className="fa-solid fa-caret-up text-white"></i>
        </div>
    )
}




export default function Element({ element, root, seo }) {
    switch (element) {
        case "root": return Root({ root: root })
        case "seo": return Seo({ seo: seo })
        case "top": return <Top />
    }
}