import axios from "axios";
import { useEffect, useState } from "react";
import Module from "../library/module";
import Element from "../library/element";

function Booking() {

    const [Oder, setOder] = useState(0)
    const [Person, setPerson] = useState(0)
    const [Dates, setDates] = useState("")
    const [Times, setTimes] = useState("")
    const [Gender, setGender] = useState(0)
    const [Name, setName] = useState("")
    const [Surname, setSurname] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Error, setError] = useState({ status: true, target: 0, type: "", text: "" })

    const [Data, setData] = useState({
        image: "",
        slogan: "",
        title: "",
        text: ""
    })
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242120' AND page_id='_page_tisch_reservation'`)
        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);
            setData({
                image: r["image"]["blob"],
                slogan: r["slogan"],
                title: r["title"],
                text: r["text"]
            })
        }
    }
    const Control = () => {
        if (Person === 0) { setError({ status: false, target: 1, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Dates === "") { setError({ status: false, target: 2, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Times === "") { setError({ status: false, target: 3, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Gender === 0) { setError({ status: false, target: 4, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Name === "") { setError({ status: false, target: 5, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Surname === "") { setError({ status: false, target: 6, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Email === "") { setError({ status: false, target: 7, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Phone === "") { setError({ status: false, target: 8, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        return true;
    }
    const Send = () => {
        if (Control()) {
            const root = Element({ element: "root", root: "default" });
            //FIRMA
            const companyBody = `
                Sie haben eine neue Reservierung erhalten, die Kundendaten sind unten aufgeführt.
                <br/><br/>
                Anzahl der Personen: ${Person}
                <br/>
                Anrede: ${Gender}
                <br/>
                Name und Vorname: ${Name} ${Surname}
                <br/>
                Datum: ${Dates}
                <br/>
                Zeit: ${Times}
                <br/>
                E-Mail: ${Email}
                <br/>
                Telefon: ${Phone}
                <br/>
            `
            const companyData = {
                id: "20242120",
                email: ["info@thawolo.ch", "n.n.akcakaya@gmail.com"],
                title: "NEUE TISCH RESERVATION",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, }).then((data) => {
                console.log(data)
            })
            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${Name} ${Surname}` : `Sehr geehrter ${Gender} ${Name} ${Surname}`}
                <br/><br/>
                Wir haben Ihre Reservierungsanfrage erhalten und erfolgreich registriert.
                <br/><br/>
                Wir danken Ihnen für Ihre Reservierung und heissen Sie in unserem Restaurant herzlich willkommen!
                <br/><br/>
                Details Ihrer Reservierung
                <br/>
                Datum: ${Dates}
                <br/>
                Zeit: ${Times}
                <br/><br/>
                Freundlichen Grüsse
                <br/><br/>
                Thawolo Team
                <br/><br/>
                Ristorante Thawolo da Chrottebach
                <br/>
                Gotthardstrasse 36 
                <br/>
                8800 Thalwil
                <br/><br/>
                info@thawolo.ch
                <br/>
                044 710 91 91
                <br/>
            `;
            const customerData = {
                id: "20242120",
                email: ["" + Email + ""],
                title: "Reservierungsbestätigung",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, }).then((data) => {
                console.log(data)
            })
            setError({ status: false, type: "success", text: "Vielen Dank für Ihre Reservierungsanfrage! Ihre Reservationsbestätigung wird Ihnen per E-Mail zugeschickt." });
            setOder(1)
            setPerson(1)
            setDates("")
            setTimes("")
            setGender("")
            setName("")
            setSurname("")
            setEmail("")
            setPhone("")
        }
    }
    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Module module="section" section={{ topclassname: "bg-thawolo", classname: "p-0" }}>
                <div className="row m-0">
                    <div className="row m-0">
                        <div className="col-lg-6 p-0">
                            <div style={{ backgroundImage: `url(${Data.image})` }} className="booking-image"></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row d-flex justify-content-center lg-pt-c10 lg-pb-c10">
                                <div className="col-lg-10">
                                    <div className="booking-title lg-pb-0 md-pt-c3">{Data.title}</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="booking-text lg-pt-c0 lg-pb-c2 md-pb-c2 md-pt-c1" dangerouslySetInnerHTML={{ __html: Data.text }}></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className={`alert alert-${Error.type}`} hidden={Error.status}>{Error.text}</div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card w-100">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 mb-2 md-p-0">
                                                    <label className="mb-1 label-text">Anzahl der Personen *</label>
                                                    <div className="mb-2 d-flex">
                                                        <button className={`btn btn-thawolo-line me-1 h-thawolo w-100 ${parseInt(Error.target) === 1 ? "border-red" : ""} ${Person === 1 ? "active" : ""}`} onClick={() => { setPerson(1); setOder(1); setError({ status: true, type: "", text: "" }) }}>1</button>
                                                        <button className={`btn btn-thawolo-line me-1 h-thawolo w-100 ${parseInt(Error.target) === 1 ? "border-red" : ""} ${Person === 2 ? "active" : ""}`} onClick={() => { setPerson(2); setOder(2); setError({ status: true, type: "", text: "" }) }}>2</button>
                                                        <button className={`btn btn-thawolo-line me-1 h-thawolo w-100 ${parseInt(Error.target) === 1 ? "border-red" : ""} ${Person === 3 ? "active" : ""}`} onClick={() => { setPerson(3); setOder(3); setError({ status: true, type: "", text: "" }) }}>3</button>
                                                        <button className={`btn btn-thawolo-line me-1 h-thawolo w-100 ${parseInt(Error.target) === 1 ? "border-red" : ""} ${Person === 4 ? "active" : ""}`} onClick={() => { setPerson(4); setOder(4); setError({ status: true, type: "", text: "" }) }}>4</button>
                                                        <button className={`btn btn-thawolo-line me-1 h-thawolo w-100 ${parseInt(Error.target) === 1 ? "border-red" : ""} ${Person === 5 ? "active" : ""}`} onClick={() => { setPerson(5); setOder(5); setError({ status: true, type: "", text: "" }) }}>5</button>
                                                        <button className={`btn btn-thawolo-line me-1 h-thawolo w-100 ${parseInt(Error.target) === 1 ? "border-red" : ""} ${Person >= 6 ? "active" : ""}`} onClick={() => { setOder(6); setPerson(6); setError({ status: true, type: "", text: "" }) }}>Oder</button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-2 md-p-0">
                                                    <div className="mb-2" hidden={Oder >= 6 ? false : true}>
                                                        <label className="mb-1 label-text">Anzahl der Personen auswählen  *</label>
                                                        <input type="number" className={`form-control h-50px shadow-sm ${parseInt(Error.target) === 1 ? "border-red" : ""}`} placeholder="Anzahl der Personen auswählen *" value={Person} onChange={(e) => { setPerson(parseInt(e.target.value)); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>


                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Datum auswählen  *</label>
                                                        <Module module="calendar" calendar={{ response: (e) => { setDates(e); setError({ status: true, type: "", text: "" }) }, classname: `${parseInt(Error.target) === 2 ? "border-red" : ""}` }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Zeit auswählen  *</label>
                                                        <Module module="timer" timer={{ selected: Dates, response: (e) => { setTimes(e); setError({ status: true, type: "", text: "" }) }, classname: `${parseInt(Error.target) === 3 ? "border-red" : ""}` }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Andrede  *</label>
                                                        <select className={`form-control h-50px shadow-sm cursor-pointer ${parseInt(Error.target) === 4 ? "border-red" : ""}`} onChange={(e) => { setGender(e.target.value); setError({ status: true, type: "", text: "" }) }}>
                                                            <option value={0}>Andrede</option>
                                                            <option value={"Frau"}>Frau</option>
                                                            <option value={"Herr"}>Herr</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6"></div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Name  *</label>
                                                        <input className={`form-control h-50px shadow-sm ${parseInt(Error.target) === 5 ? "border-red" : ""}`} placeholder="Name *" value={Name} onChange={(e) => { setName(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Vorname  *</label>
                                                        <input className={`form-control h-50px shadow-sm form-control h-50px shadow-sm ${parseInt(Error.target) === 6 ? "border-red" : ""}`} placeholder="Vorname *" value={Surname} onChange={(e) => { setSurname(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">E-mail  *</label>
                                                        <input className={`form-control h-50px shadow-sm form-control h-50px shadow-sm form-control h-50px shadow-sm ${parseInt(Error.target) === 7 ? "border-red" : ""}`} placeholder="E-mail *" value={Email} onChange={(e) => { setEmail(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Telefon  *</label>
                                                        <input className={`form-control h-50px shadow-sm ${parseInt(Error.target) === 8 ? "border-red" : ""}`} placeholder="Telefon *" value={Phone} onChange={(e) => { setPhone(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 md-p-0 mt-2">
                                                    <div className="mb-2">
                                                        <button className="btn btn-thawolo w-100" onClick={() => { Send() }}>SENDEN</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Booking;