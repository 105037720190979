import axios from "axios";
import { useEffect, useState } from "react";
import Module from "../library/module";
import Element from "../library/element";


function Feiern() {

    const [Indicator, setIndicator] = useState(false)
    const [Dates, setDates] = useState("")
    const [Gender, setGender] = useState(0)
    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Phone, setPhone] = useState("")
    const [Comment, setComment] = useState("")
    const [Error, setError] = useState({ status: true, target: 0, type: "", text: "" })

    const [Data, setData] = useState({
        image: "",
        slogan: "",
        title: "",
        text: "",
        email: "",
        phone: ""
    })
    const Load = async () => {
        const result = await axios.get(`${Element({ element: "root", root: "default" })}?params=select&table=kp_page&conditions=company_id='20242120' AND page_id='_page_feiern'`)


        if (result.data.length > 0) {
            const r = JSON.parse(result.data[0]["content"]);

            console.log(r)
            setData({
                image: r["image"]["blob"],
                slogan: r["slogan"],
                title: r["title"],
                text: r["text"],
                email: r["email"],
                phone: r["phone"],
            })
        }
    }
    const Control = () => {

        if (Dates === "") { setError({ status: false, target: 1, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Gender === 0) { setError({ status: false, target: 2, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (User === "") { setError({ status: false, target: 3, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Email === "") { setError({ status: false, target: 4, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        if (Phone === "") { setError({ status: false, target: 5, type: "danger", text: "Diese Felder müssen ausgefüllt werden." }); window.scroll({ top: 200, left: 0, behavior: "smooth" }); return false }
        return true;
    }
    const Send = () => {
        if (Control()) {
            setIndicator(true)
            const root = Element({ element: "root", root: "default" });
            //FIRMA
            const companyBody = `
                Sie haben eine neue Veranstaltungsanfrage erhalten. Die folgenden Informationen gehören dem Antragsteller. Bitte setzen Sie sich mit dem Bewerber in Verbindung.
                <br/><br/>
                gewünschtes Datum:: ${Dates}
                <br/>
                Anrede: ${Gender}
                <br/>
                Name und Vorname: ${User}
                <br/>
                E-Mail: ${Email}
                <br/>
                Telefon: ${Phone}
                <br/>
                Bemerkungen: ${Comment}
                <br/>
            `
            const companyData = {
                id: "202421120",
                email: ["info@thawolo.ch", "n.n.akcakaya@gmail.com"],
                title: "NEUE TISCH RESERVATION",
                body: companyBody
            };
            axios.post(root, { params: "email", data: companyData, })
            //MÜŞTERI
            const customerBody = `
                ${Gender === "Frau" ? `Sehr geehrte ${Gender} ${User}` : `Sehr geehrter ${Gender} ${User}`}
                <br/><br/>
                Ihre Nachricht ist bei uns angekommen, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.
                <br/><br/>
                Freundlichen Grüsse
                <br/><br/>
                Thawolo Team
                <br/><br/>
                Ristorante Thawolo da Chrottebach
                <br/>
                Gotthardstrasse 36
                <br/>
                8800 Thalwil
                <br/><br/>
                info@thawolo.ch
                <br/>
                044 710 91 91
                <br/>
            `;
            const customerData = {
                id: "202421120",
                email: ["" + Email + ""],
                title: "Ihre Nachricht ist bei uns angekommen",
                body: customerBody
            };
            axios.post(root, { params: "email", data: customerData, })
            setError({ status: false, type: "success", text: "Es gibt fehlende Informationen im Formular, bitte überprüfen Sie es." });
            setDates("")
            setGender("")
            setEmail("")
            setPhone("")
            setIndicator(false)
        }
    }

    useEffect(() => {
        Load()
    }, [])

    return (
        <>
            <Module module="section" section={{ topclassname: "bg-thawolo", classname: "p-0" }}>
                <div className="row m-0">
                    <div className="row m-0">
                        <div className="col-lg-6 p-0">
                            <div style={{ backgroundImage: `url(${Data["image"]})` }} className="booking-image"></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row d-flex justify-content-center lg-pt-c10 lg-pb-c10">
                                <div className="col-lg-10">
                                    <div className="booking-title lg-pb-5 md-pt-c3 md-pt-c0 md-pb-c2">{Data["title"]}</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="booking-text lg-pt-c0 lg-pb-c2" dangerouslySetInnerHTML={{ __html: Data["text"] }}></div>
                                </div>
                                <div className="col-lg-6 col-12 lg-pt-c0 lg-pb-c2">
                                    <div className="booking-phone cursor-pointer" onClick={() => { window.open(`tel:${Data["phone"]}`) }}>{Data["phone"]}</div>
                                </div>
                                <div className="col-lg-6 col-12 lg-pt-c0 lg-pb-c2 md-pt-c0 md-pb-c2">
                                    <div className="booking-email cursor-pointer" onClick={() => { window.open(`mailto:${Data["email"]}`) }}>{Data["email"]}</div>
                                </div>
                                <div className="col-lg-12">
                                    <div className={`alert alert-${Error.type}`} hidden={Error.status}>
                                        {Error.text}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card w-100">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Datum auswählen  *</label>
                                                        <Module module="calendar" calendar={{ response: (e) => { setDates(e); setError({ status: true, type: "", text: "" }) }, classname: `${parseInt(Error.target) === 1 ? "border-red" : ""}` }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Andrede  *</label>
                                                        <select className={`form-control h-50px shadow-sm cursor-pointer ${parseInt(Error.target) === 2 ? "border-red" : ""}`} onChange={(e) => { setGender(e.target.value); setError({ status: true, type: "", text: "" }) }}>
                                                            <option value={0}>Andrede</option>
                                                            <option value={"Frau"}>Frau</option>
                                                            <option value={"Herr"}>Herr</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Name und Vorname  *</label>
                                                        <input className={`form-control h-50px shadow-sm ${parseInt(Error.target) === 3 ? "border-red" : ""}`} placeholder="Name und Vorname *" value={User} onChange={(e) => { setUser(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">E-Mail Adresse *</label>
                                                        <input className={`form-control h-50px shadow-sm form-control h-50px shadow-sm ${parseInt(Error.target) === 4 ? "border-red" : ""}`} placeholder="E-Mail Adresse *" value={Email} onChange={(e) => { setEmail(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Telefon *</label>
                                                        <input className={`form-control h-50px shadow-sm form-control h-50px shadow-sm form-control h-50px shadow-sm ${parseInt(Error.target) === 5 ? "border-red" : ""}`} placeholder="Telefon *" value={Phone} onChange={(e) => { setPhone(e.target.value); setError({ status: true, type: "", text: "" }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-2 md-p-0">
                                                    <div className="mb-2">
                                                        <label className="mb-1 label-text">Nachricht  *</label>
                                                        <textarea className={`form-control shadow-sm ${parseInt(Error.target) === 6 ? "border-red" : ""}`} placeholder="Nachricht... *" rows={5} value={Comment} onChange={(e) => { setComment(e.target.value); setError({ status: true, type: "", text: "" }) }}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 md-p-0 mt-2">
                                                    <div className="mb-2">
                                                        <button className="btn btn-thawolo w-100" onClick={() => { Send() }}>
                                                            {Indicator ? <div className="indicator me-1"></div> : <></>}
                                                            {Indicator ? "Senden..." : "SENDEN"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Module>
        </>
    )
}
export default Feiern;